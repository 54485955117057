import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Modal, Button } from 'antd';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from './IdleModal'
import { withRouter } from "react-router-dom";
import { BookOutlined, TrophyOutlined, TeamOutlined, PhoneOutlined, DollarOutlined, IdcardFilled, FormOutlined, IdcardOutlined, FileDoneOutlined, HomeOutlined, CloseCircleFilled, AreaChartOutlined, SettingOutlined, DashboardOutlined, UploadOutlined, ToolOutlined } from '@ant-design/icons';
const { Header, Content } = Layout;
const { SubMenu } = Menu;

// export default props => (
    class MainLayout extends React.Component {
        constructor(props){
            super(props)
    
            this.state = {
                timeout:1000 * 4200 * 1,
                showModal: false,
                userLoggedIn: false,
                isTimedOut: false,
                showModalSupport: false
            }
    
            this.idleTimer = null
            this.onAction = this._onAction.bind(this)
            this.onActive = this._onActive.bind(this)
            this.onIdle = this._onIdle.bind(this)
            this.handleClose = this.handleClose.bind(this)
            this.handleLogout = this.handleLogout.bind(this)
            this.handleCloseSupport = this.handleCloseSupport.bind(this)
        }
        handleClose() {
            this.setState({showModal: false})
        }
        handleCloseSupport() {
            this.setState({showModalSupport: false})
        }
        handleLogout() {
            localStorage.removeItem("agencyId");
            this.setState({showModal: false});
            this.props.history.push('/');
        }

        _onAction(e) {
          //console.log('user did something', e)
          this.setState({isTimedOut: false})
        }
       
        _onActive(e) {
          //console.log('user is active', e)
          this.setState({isTimedOut: false})
        }
       
        _onIdle(e) {
          //console.log('user is idle', e)
          const isTimedOut = this.state.isTimedOut
          if (isTimedOut) {
            localStorage.removeItem("agencyId");
            this.props.history.push('/');
          } else {
            this.setState({showModal: true})
            this.idleTimer.reset();
            this.setState({isTimedOut: true})
          }
        }
        render(){
            const userStatus = localStorage.getItem('status');
            const agencyId = localStorage.getItem('agencyId');
            return(
                <div>
                    <IdleTimer
                        ref={ref => { this.idleTimer = ref }}
                        element={document}
                        onActive={this.onActive}
                        onIdle={this.onIdle}
                        onAction={this.onAction}
                        debounce={250}
                        timeout={this.state.timeout} />
                    <Layout style={{backgroundColor: '#fff'}}>
                        <Header style={{backgroundColor: '#fff', width: '85%'}}>
                            <img src="humblelogo.png" alt="Logo" style={{float: "left", height: 65}} />
                            {
                                userStatus == 4 ?
                                <Menu mode="horizontal" style={{ background: '#fff' }}>
                                    <Menu.Item key="1" style={{fontSize: '16px'}}><Link to={'/Dashboard'}><DashboardOutlined style={{fontSize: '20px'}} /> Dashboard</Link></Menu.Item>
                                    <Menu.Item key="2" style={{fontSize: '16px'}}><Link to={'/Settings'}><SettingOutlined style={{fontSize: '20px'}} /> Settings</Link></Menu.Item>
                                    <Menu.Item key="7" style={{fontSize: '16px'}}><Link to={'/quotelog'}><FormOutlined style={{fontSize: '20px'}} /> Activity Log</Link></Menu.Item>
                                    <Menu.Item key="10" style={{fontSize: '16px'}}><Link to={'/onboarding'}><FileDoneOutlined style={{fontSize: '20px'}} /> Onboarding</Link></Menu.Item>
                                    <SubMenu key="SubMenu" style={{fontSize: '16px'}} title={<span><AreaChartOutlined style={{fontSize: '20px'}} /> Reports</span>}>
                                        <Menu.Item key="sub:1"><Link to={'/vendoractivitysummary'}>Lead Source Performance</Link></Menu.Item>
                                        <Menu.Item key="sub:3"><Link to={'/zipcodesummary'}>Zip Code Performance</Link></Menu.Item>
                                        <Menu.Item key="sub:5"><Link to={'/policysummary'}>Product Performance</Link></Menu.Item>
                                        <Menu.Item key="sub:8"><Link to={'/currentcarrier'}>Current Carrier Performance</Link></Menu.Item>
                                        <Menu.Item key="sub:9"><Link to={'/referringagentsummary'}>Referring Agent Summary</Link></Menu.Item>
                                        <Menu.Item key="sub:6"><Link to={'/graphs'}>Graphs and Charts</Link></Menu.Item>
                                        <Menu.Item key="sub:7"><Link to={'/dnqreport'}>Did Not Qualify</Link></Menu.Item>
                                        <Menu.ItemGroup title="Data Validation">
                                            <Menu.Item key="val:1">
                                                <Link to={'/seriousuploadreport'}>Quotes</Link>
                                            </Menu.Item>
                                            <Menu.Item key="val:2">
                                                <Link to={'/newbusinessuploadreport'}>New Business</Link>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </SubMenu>
                                    <Menu.Item key="4" style={{fontSize: '16px'}}><Link to={'/marketing'}><PhoneOutlined style={{fontSize: '20px'}} /> Marketing</Link></Menu.Item>
                                    <SubMenu key="Uploads" style={{fontSize: '16px'}} title={<span><UploadOutlined style={{fontSize: '20px'}} /> Uploads</span>}>
                                        <Menu.Item key="upload:1"><Link to={'/seriousupload'}>Quote Upload</Link></Menu.Item>
                                        <Menu.Item key="upload:2"><Link to={'/newbusinessupload'}>New Business Upload</Link></Menu.Item>
                                        <Menu.Item key="upload:4"><Link to={'/zipupload'}>Zip Code Upload</Link></Menu.Item>
                                        <Menu.Item key="upload:5"><Link to={'/uploadeducation'}>Your Agency Education Upload</Link></Menu.Item>
                                    </SubMenu>
                                    <SubMenu key="Education" style={{fontSize: '16px'}} title={<span><BookOutlined style={{fontSize: '20px'}} /> Education</span>}>
                                        <Menu.Item key="education:1"><Link to={'/education'}>Humble100 Education</Link></Menu.Item>
                                        <Menu.Item key="education:2"><Link to={'/agencyedumaterials'}>Your Agency Education</Link></Menu.Item>
                                    </SubMenu>
                                    <Menu.Item key="9" style={{fontSize: '16px'}} onClick={() => this.setState({showModalSupport: true})}><ToolOutlined style={{fontSize: '20px'}} /> Support</Menu.Item>
                                    <Menu.Item key="8" style={{fontSize: '16px', position: 'absolute', right: 0}} onClick={this.handleLogout}><Link to={'/'} style={{color: 'Red'}}><CloseCircleFilled style={{fontSize: '20px', color: 'Red'}} /> Log Out</Link></Menu.Item>
                                </Menu>
                                : userStatus == 1 ?
                                <Menu mode="horizontal" style={{ background: '#fff' }}>
                                    <Menu.Item key="5" style={{fontSize: '16px'}}><Link to={'/quotelog'}><FormOutlined style={{fontSize: '20px'}} /> Activity Log</Link></Menu.Item>
                                    <SubMenu key="SubMenu" style={{fontSize: '16px'}} title={<span><AreaChartOutlined style={{fontSize: '20px'}} /> Reports</span>}>
                                        <Menu.Item key="sub:5"><Link to={'/policysummary'}>Product Performance</Link></Menu.Item>
                                        <Menu.Item key="sub:8"><Link to={'/currentcarrier'}>Current Carrier Performance</Link></Menu.Item>
                                        <Menu.Item key="sub:9"><Link to={'/referringagentsummary'}>Referring Agent Summary</Link></Menu.Item>
                                        <Menu.Item key="sub:6"><Link to={'/graphs'}>Graphs and Charts</Link></Menu.Item>
                                        <Menu.ItemGroup title="Data Validation">
                                            <Menu.Item key="val:1">
                                                <Link to={'/seriousuploadreport'}>Quotes</Link>
                                            </Menu.Item>
                                            <Menu.Item key="val:2">
                                                <Link to={'/newbusinessuploadreport'}>New Business</Link>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </SubMenu>
                                    <SubMenu key="Education" style={{fontSize: '16px'}} title={<span><BookOutlined style={{fontSize: '20px'}} /> Education</span>}>
                                        <Menu.Item key="education:1"><Link to={'/education'}>Humble100 Education</Link></Menu.Item>
                                        <Menu.Item key="education:2"><Link to={'/agencyedumaterials'}>Your Agency Education</Link></Menu.Item>
                                    </SubMenu>
                                    {/* <SubMenu key="Uploads" style={{fontSize: '16px'}} title={<span><UploadOutlined style={{fontSize: '20px'}} /> Uploads</span>}>
                                        <Menu.Item key="upload:1"><Link to={'/seriousuploadreport'}>Quote Upload</Link></Menu.Item>
                                        <Menu.Item key="upload:2"><Link to={'/newbusinessuploadreport'}>New Business Upload</Link></Menu.Item>
                                    </SubMenu> */}
                                    <Menu.Item key="7" style={{fontSize: '16px', position: 'absolute', right: 0}}><Link to={'/'} style={{color: 'Red'}}><CloseCircleFilled style={{fontSize: '20px', color: 'Red'}} /> Log Out</Link></Menu.Item>
                                </Menu>
                                :
                                <Menu mode="horizontal" style={{ background: '#fff' }}>
                                    <Menu.Item key="1" style={{fontSize: '16px'}}><Link to={'/quotelog'}><FormOutlined style={{fontSize: '20px'}} /> Activity Log</Link></Menu.Item>
                                    <SubMenu key="SubMenu" style={{fontSize: '16px'}} title={<span><AreaChartOutlined style={{fontSize: '20px'}} /> Reports</span>}>
                                        <Menu.Item key="setting:1"><Link to={'/vendoractivitysummary'}>Lead Source Performance</Link></Menu.Item>
                                        <Menu.Item key="sub:8"><Link to={'/currentcarrier'}>Current Carrier Performance</Link></Menu.Item>
                                        <Menu.Item key="sub:9"><Link to={'/referringagentsummary'}>Referring Agent Summary</Link></Menu.Item>
                                        <Menu.ItemGroup title="Data Validation">
                                            <Menu.Item key="val:1">
                                                <Link to={'/seriousuploadreport'}>Quotes</Link>
                                            </Menu.Item>
                                            <Menu.Item key="val:2">
                                                <Link to={'/newbusinessuploadreport'}>New Business</Link>
                                            </Menu.Item>
                                        </Menu.ItemGroup>
                                    </SubMenu>
                                    <SubMenu key="Education" style={{fontSize: '16px'}} title={<span><BookOutlined style={{fontSize: '20px'}} /> Education</span>}>
                                        <Menu.Item key="education:1"><Link to={'/education'}>Humble100 Education</Link></Menu.Item>
                                        <Menu.Item key="education:2"><Link to={'/agencyedumaterials'}>Your Agency Education</Link></Menu.Item>
                                    </SubMenu>
                                    {/* <SubMenu key="Uploads" style={{fontSize: '16px'}} title={<span><UploadOutlined style={{fontSize: '20px'}} /> Uploads</span>}>
                                        <Menu.Item key="upload:1"><Link to={'/seriousuploadreport'}>Quote Upload</Link></Menu.Item>
                                        <Menu.Item key="upload:2"><Link to={'/newbusinessuploadreport'}>New Business Upload</Link></Menu.Item>
                                    </SubMenu> */}
                                    <Menu.Item key="2" style={{fontSize: '16px', position: 'absolute', right: 0}}><Link to={'/'} style={{color: 'Red'}}><CloseCircleFilled style={{fontSize: '20px', color: 'Red'}} /> Log Out</Link></Menu.Item>
                                </Menu>
                            }
                        </Header>
                        <Content style={{ background: '#fff', minHeight: 280, padding: 20 }}>{this.props.children}</Content>
                    </Layout>
                    <IdleTimeOutModal 
                        showModal={this.state.showModal}
                        handleClose={this.handleClose}
                        handleLogout={this.handleLogout}
                    />
                    <Modal width={1000}
                        title="Support"
                        visible={this.state.showModalSupport}
                        onOk={this.handleCloseSupport}
                        onCancel={this.handleCloseSupport}
                        footer={[
                        // <Button key="back">
                        //     Submit
                        // </Button>,
                        <Button key="submit" type="primary" onClick={this.handleCloseSupport}>
                            Ok
                        </Button>,
                        ]}>
                        Please send all questions or concerns to <a href="mailto:support@th100consulting.com">support@th100consulting.com</a>
                    </Modal>
                </div>
            );
        }
    }
    export default withRouter(MainLayout);