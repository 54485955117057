import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/humbleActions';
import { Select, Table, Row, DatePicker, Col, Space, Button, Radio, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Search } = Input;

class CostAcquisition extends Component {
    constructor(props) {
        super(props);
          this.state = {
            dateFrom: moment().startOf('month').format("MM/DD/YYYY"),
            dateTo: moment().endOf('month').format("MM/DD/YYYY"),
            monthDts: moment().format("MMM YYYY")
        };
        this.onMonthChange = this.onMonthChange.bind(this);
    }
    componentDidMount() {
        const agencyId = localStorage.getItem('agencyId');
        this.props.getCostAcquisition(agencyId, this.state.dateFrom, this.state.dateTo, moment().format("MMM YYYY"));
    }
    onMonthChange(date, dateString){
        if(dateString != ''){
            const userId = localStorage.getItem("userId");
            const agencyId = localStorage.getItem("agencyId");
            this.setState({monthDts: dateString, selectedDate: date});
            this.props.getMarketingSummary(agencyId, dateString);
            const dateTo = moment(date).endOf('month').format("MM/DD/YYYY");
            const dateFrom = moment(date).startOf('month').format("MM/DD/YYYY");
            this.props.getCostAcquisition(agencyId, dateFrom, dateTo, dateString);
        }else{
            this.setState({monthDts: moment().format("MMM YYYY")});
        }
    }
    render(){
        const dateFormat = 'MM/DD/YYYY';
        const columns = [
            {
                title: 'Lead Source',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Spend',
                dataIndex: 'actualSpend',
                key: 'actualSpend',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Written Premium',
                dataIndex: 'writtenPremium',
                key: 'writtenPremium',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Cost per Quoted Household',
                dataIndex: 'actualHouseholdCost',
                key: 'actualHouseholdCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Cost per Quoted Policy',
                dataIndex: 'actualPolicyCost',
                key: 'actualPolicyCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Cost per Quoted Item',
                dataIndex: 'actualItemCost',
                key: 'actualItemCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Household Acq. Cost',
                dataIndex: 'actualHouseholdAcquistionCost',
                key: 'actualHouseholdAcquistionCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Policy Acq. Cost',
                dataIndex: 'actualPolicyAcquistionCost',
                key: 'actualPolicyAcquistionCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
            {
                title: 'Item Acq. Cost',
                dataIndex: 'actualItemAcquistionCost',
                key: 'actualItemAcquistionCost',
                align: 'center',
                render: text => new Intl.NumberFormat("en-US", {style: "currency",currency: "USD"}).format(text),
            },
          ];
        return(
            <div>
                <Row style={{paddingBottom: 5}}>
                    <Col md={8}>
                        <strong>Filter month</strong><br />
                        <DatePicker onChange={this.onMonthChange} picker="month" format="MMM YYYY" value={moment(this.state.monthDts, "MMM YYYY")}  />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table dataSource={this.props.acquisitionCost} columns={columns} pagination={{ pageSize: 50 }} />
                    </Col>
                </Row>
            </div>
        )
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(CostAcquisition);