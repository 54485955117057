import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { actionCreators } from '../store/humbleActions';
import { UserOutlined, TrophyOutlined, TeamOutlined, IdcardFilled, FileOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { List, Typography, Row, Col, Avatar } from 'antd';
const { Title } = Typography;

class Settings extends Component {
    constructor(props) {
      super(props);
        this.state = {
           
        }
    }
    componentDidMount(){
        const userId = localStorage.getItem('userId');
        const agencyId = localStorage.getItem('agencyId');
        if(agencyId == null){
            this.props.history.push('/');
        }
    }

    renderRedirect = () => {
        let path = 'quotelog';
        this.props.history.push(path);
    }
    render() {
        const settings = [
            {
                title: 'Users',
                description: 'Add, Update and Remove Users for your Agency',
                url: '/Agency',
                icon: <UserOutlined />
            },
            {
                title: 'Agency Goals',
                description: 'Setup specific goals for your Agency',
                url: '/AgencyGoals',
                icon: <TrophyOutlined />
            },
            {
                title: 'Sales Producer Goals',
                description: 'Setup specific goals for your Agents',
                url: '/AgentGoals',
                icon: <TeamOutlined />
            },
            {
                title: 'Marketing Bucket',
                description: 'Add, Update and Remove Marketing Strategies for your Agency',
                url: '/VendorType',
                icon: <FileOutlined />
            },
            {
                title: 'Lead Source',
                description: 'Add, Update and Remove Lead Sources for your Agency',
                url: '/Vendors',
                icon: <IdcardFilled />
            },
            {
                title: 'Referring Agents',
                description: 'Add, Update and Remove Referring Agents for your Agency',
                url: '/ReferringAgent',
                icon: <UsergroupAddOutlined />
            },
          ];
          const salesAgentSettings = [
            {
                title: 'Old Sales Agents Goals',
                description: 'Setup specific goals for your Agents',
                url: '/SalesAgent',
                icon: <UserOutlined />
            },
        ];
        const userId = localStorage.getItem('userId');
        return (
            <div>
            <Row>
                <Col md={24}>
                    <List header={<Title level={3}>Agency Settings</Title>}
                        itemLayout="horizontal"
                        dataSource={settings}
                        renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar size={64} icon={item.icon} />}
                                title={<Link to={item.url}><Title level={4}>{item.title}</Title></Link>}
                                description={item.description}
                            />
                        </List.Item>
                        )}
                    />
                </Col>
            </Row>
            {userId == '1745216572' &&
            <Row>
                <Col md={24}>
                    <List
                        itemLayout="horizontal"
                        dataSource={salesAgentSettings}
                        renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar size={64} icon={item.icon} />}
                                title={<Link to={item.url}><Title level={4}>{item.title}</Title></Link>}
                                description={item.description}
                            />
                        </List.Item>
                        )}
                    />
                </Col>
            </Row>
            }
        </div>
        );
    }
}
export default connect(
    state => state.action,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Settings);